import { Listbox, ListboxButton, ListboxOption, ListboxOptions } from "@headlessui/react"
import { House } from "../icons/House"
import { ChevronDown } from "../icons/ChevronDown"
import { ChevronUp } from "../icons/ChevronUp"

type Item = {
  id: string
  name: string
}

type ListBoxProps = {
  items: Item[]
  selectedItem: Item
  setSelectedItem: (item: Item) => unknown
}

export const DropDownList = ({ items, selectedItem, setSelectedItem }: ListBoxProps) => {
  return (
    <Listbox value={selectedItem} onChange={setSelectedItem}>
      {({ open }) => (
        <>
          <ListboxButton className="group w-full rounded-xl p-2 hover:bg-white">
            <div className="flex items-center justify-between gap-2">
              <House className="stroke-black text-xl text-light-grey group-hover:stroke-white" />
              <div className="flex-1 text-left text-p-small">{selectedItem.name}</div>
              <div className="text-[18px]">{open ? <ChevronUp /> : <ChevronDown />}</div>
            </div>
          </ListboxButton>
          <div className="relative">
            <ListboxOptions className="absolute top-1 max-h-64 w-full gap-1 overflow-auto rounded-xl bg-white p-3 shadow-light-shadow">
              {items.map((item) => (
                <ListboxOption
                  key={item.id}
                  value={item}
                  className="flex cursor-pointer items-center rounded-lg px-3 py-2 ui-selected:bg-extra-light-grey ui-active:bg-extra-light-grey ui-not-active:bg-inherit"
                >
                  {item.name}
                </ListboxOption>
              ))}
            </ListboxOptions>
          </div>
        </>
      )}
    </Listbox>
  )
}
