import {
  ChannelList,
  ChannelListMessengerProps,
  LoadMorePaginator,
  useChannelListContext,
  useChatContext,
} from "stream-chat-react"
import { DropDownFiltreMessagerie, FiltreMessagerie } from "./FiltreMessagerie"
import { ConversationCard } from "./ConversationCard"
import { ToggleMessagesATraiter } from "./components/ToggleMessagesATraiter"
import { channelRenderFilterFn, channelRenderFilterSearchFn, ConversationTrouvee } from "./channelRenderFilterFn"
import { PlainButton } from "@ds"
import { ChannelSort } from "stream-chat"
import { usePreferences } from "@infra/preferences/usePreferences"
import { useTranslation } from "react-i18next"
import { useEffect, useState } from "react"
import { CustomList } from "./CustomList"
import { filtreConversations } from "./filtreConversations"
import { useThrottleMutateOnChange } from "@features/rendezVous/detailPreconsultation/hooks/useThrottleMutateOnChange"
import { ChannelListEmptyStateIndicator } from "./ChannelListEmptyStateIndicator"

export const ListeDesConversations = () => {
  const { t } = useTranslation()
  const { client } = useChatContext()
  const isUserMedecin = client.user?.metier === "medecin"

  const options = { limit: 30, watch: true }
  const sort = isUserMedecin ? ({ demandeAideMedecin: 1, last_message_at: -1 } as unknown as ChannelSort) : undefined

  const {
    identifiantDuCabinet,
    filtreInboxMessagerie,
    filtreParticipantsMessagerie,
    messagesATraiterUniquement,
    setPreferences,
  } = usePreferences()
  const filtreDesConversations = filtreConversations(identifiantDuCabinet!, client.user!.id, isUserMedecin)

  const [filteredChannelsCount, setFilteredChannelsCount] = useState(0)
  const [recherche, setRecherche] = useState("")

  const inboxOptions = [
    {
      id: "ma-boite",
      nom: isUserMedecin ? t("messagerie.mesPatients") : t("messagerie.maBoite"),
    },
    ...(!isUserMedecin ? [{ id: "tous", nom: t("messagerie.tous") }] : []),
    {
      id: "archive",
      nom: t("messagerie.archives"),
    },
  ]
  const filtreInboxChoisi = inboxOptions.find((inboxType) => inboxType.id === filtreInboxMessagerie) || inboxOptions[0]

  const [listeChannelRecherches, setListeChannelsRecherches] = useState<ConversationTrouvee[]>([])
  const conversationsTrouveesIds = listeChannelRecherches.map((conversation) => conversation.id)

  useThrottleMutateOnChange(
    ({ recherche, filtreParticipantsMessagerie, identifiantDuCabinet, messagesATraiterUniquement }) => {
      const fetchChannels = async () => {
        if (filtreInboxMessagerie && recherche.length > 2 && identifiantDuCabinet) {
          const conversationsTrouvees = await channelRenderFilterSearchFn(
            recherche,
            identifiantDuCabinet,
            filtreParticipantsMessagerie,
            client,
            messagesATraiterUniquement,
          )
          setListeChannelsRecherches(conversationsTrouvees)
        } else {
          setListeChannelsRecherches([])
        }
      }
      fetchChannels()
    },
    false,
    { recherche, filtreParticipantsMessagerie, identifiantDuCabinet, messagesATraiterUniquement },
    { filter: (value) => value.recherche.length >= 3, throttleDueTime: 500 },
  )

  const CustomListCountingChannels = (props: ChannelListMessengerProps) => {
    const { channels } = useChannelListContext()

    useEffect(() => {
      const filteredChannels = filtreInboxMessagerie
        ? channelRenderFilterFn(
            channels,
            filtreInboxMessagerie,
            filtreParticipantsMessagerie,
            client,
            messagesATraiterUniquement,
          )
        : []
      setFilteredChannelsCount(filteredChannels.length)
    }, [channels])

    return <CustomList {...props} filters={filtreDesConversations} idFiltreInboxChoisi={filtreInboxChoisi.id} />
  }

  return (
    <div className="flex flex-col gap-3 overflow-hidden border-r-[1px] border-extra-light-grey pr-2">
      <FiltreMessagerie
        filtres={inboxOptions}
        selection={filtreInboxChoisi}
        setSelection={(filtreSelectionne: DropDownFiltreMessagerie) =>
          setPreferences({ filtreInboxMessagerie: filtreSelectionne.id })
        }
        count={filtreInboxChoisi.id === "archive" ? null : filteredChannelsCount}
        onRecherche={(recherche) => setRecherche(recherche)}
      />
      {filtreInboxChoisi.id !== "archive" && <ToggleMessagesATraiter />}
      <div className="border-t-[1px] border-extra-light-grey"></div>
      {recherche?.length > 2 ? (
        <ChannelList
          lockChannelOrder={isUserMedecin}
          Preview={(props) => (
            <ConversationCard
              {...props}
              displaysNew={filtreInboxChoisi.id !== "archive"}
              highlight={recherche}
              message={
                Object.values(listeChannelRecherches).find((conversation) => conversation.id === props.channel.id)
                  ?.messageTrouve
              }
            />
          )}
          options={options}
          filters={{
            id: { $in: conversationsTrouveesIds },
          }}
          setActiveChannelOnMount={false}
          allowNewMessagesFromUnfilteredChannels={false}
          Paginator={(props) => (
            <LoadMorePaginator
              {...props}
              LoadMoreButton={() =>
                filtreInboxMessagerie !== "archive" && props.hasNextPage ? null : (
                  <PlainButton
                    title={t("messagerie.chargerPlus")}
                    onClick={() => {
                      if (props.hasNextPage) {
                        props.loadNextPage()
                      }
                    }}
                    type="outlined"
                    className="mx-auto my-4"
                  />
                )
              }
            />
          )}
        />
      ) : (
        <ChannelList
          lockChannelOrder={isUserMedecin}
          List={(props) => <CustomListCountingChannels {...props} />}
          Preview={(props) => <ConversationCard {...props} displaysNew={filtreInboxChoisi.id !== "archive"} />}
          options={options}
          channelRenderFilterFn={(channelList) =>
            channelRenderFilterFn(
              channelList,
              filtreInboxMessagerie!,
              filtreParticipantsMessagerie,
              client,
              messagesATraiterUniquement,
            )
          }
          filters={filtreDesConversations}
          sort={sort}
          allowNewMessagesFromUnfilteredChannels={false}
          EmptyStateIndicator={ChannelListEmptyStateIndicator}
          setActiveChannelOnMount={false}
          Paginator={(props) => (
            <LoadMorePaginator
              {...props}
              LoadMoreButton={() =>
                filtreInboxMessagerie !== "archive" && props.hasNextPage ? null : (
                  <PlainButton
                    title={t("messagerie.chargerPlus")}
                    onClick={() => {
                      if (props.hasNextPage) {
                        props.loadNextPage()
                      }
                    }}
                    type="outlined"
                    className="mx-auto my-4"
                  />
                )
              }
            />
          )}
        />
      )}
    </div>
  )
}
