import { ReactNode } from "@tanstack/react-router"
import { MotionValue, useMotionValue, useTransform, motion } from "framer-motion"
import { createContext, useContext, useEffect, useRef, useState } from "react"
import { animate } from "framer-motion"
import { classNames } from "../classNames"
import { useResizeObserver } from "usehooks-ts"

interface ShimmerContextValue {
  shimmerOffsetX: MotionValue<number>
  shimmerContainerRect: DOMRect | undefined
  subscribe: (ref: unknown) => void
  unsubscribe: (ref: unknown) => void
}

const ShimmerContext = createContext<ShimmerContextValue | null>(null)

export const ShimmerProvider = ({ children, className }: { children: ReactNode; className?: string }) => {
  const shimmerOffsetX = useMotionValue(1)
  const containerRef = useRef<HTMLDivElement>(null)
  const [shimmerContainerRect, setShimmerContainerRect] = useState<DOMRect | undefined>(undefined)
  const [attachedShimmers, setAttachedShimmers] = useState(new Set())

  useEffect(() => {
    if (attachedShimmers.size === 0 && shimmerOffsetX.isAnimating()) shimmerOffsetX.stop()
    else if (attachedShimmers.size > 0 && !shimmerOffsetX.isAnimating()) {
      shimmerOffsetX.set(1)
      animate(shimmerOffsetX, 0, {
        repeat: Infinity,
        duration: 2,
        ease: "linear",
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attachedShimmers])

  const refSize = useResizeObserver({ ref: containerRef, box: "border-box" })

  useEffect(() => {
    setShimmerContainerRect(containerRef.current?.getBoundingClientRect())
  }, [refSize.height, refSize.width])

  return (
    <ShimmerContext.Provider
      value={{
        shimmerOffsetX,
        shimmerContainerRect,
        subscribe: (ref) => {
          setAttachedShimmers((p) => new Set([...p, ref]))
        },
        unsubscribe: (ref) => {
          setAttachedShimmers((p) => {
            const newSet = new Set(p)
            newSet.delete(ref)
            return newSet
          })
        },
      }}
    >
      <div ref={containerRef} className={className}>
        {children}
      </div>
    </ShimmerContext.Provider>
  )
}

type ShimmerProps = { show?: boolean; className?: string; children?: ReactNode }

const SCREEN_WIDTH_SHIMMER_SCALE = 2

export const Shimmer = ({ show = true, className, children }: ShimmerProps) => {
  const context = useContext(ShimmerContext)
  const thisRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const current = thisRef.current
    if (current != null) context?.subscribe(current)
    return () => {
      if (current != null) context?.unsubscribe(current)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [thisRef])

  useResizeObserver({ ref: thisRef, box: "border-box" })
  const { shimmerOffsetX, shimmerContainerRect } = context ?? {
    shimmerOffsetX: new MotionValue(),
    shimmerContainerRect: undefined,
  }
  const thisRect = thisRef.current?.getBoundingClientRect()
  const backgroundPosition = useTransform(shimmerOffsetX, (dx) =>
    thisRect && shimmerContainerRect
      ? `-${thisRect.left - shimmerContainerRect.left + dx * shimmerContainerRect.width * SCREEN_WIDTH_SHIMMER_SCALE}px -${thisRect.top - shimmerContainerRect.top}px`
      : `0 0`,
  )
  if (!show) return null
  return (
    <motion.div
      exit={{ opacity: 0 }}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      ref={thisRef}
      className={classNames(className)}
      style={{
        backgroundImage: `linear-gradient(110deg, transparent 30%, rgba(255,255,255,0.5) 39%, transparent 40%)`,
        backgroundSize: shimmerContainerRect
          ? `${shimmerContainerRect.width * SCREEN_WIDTH_SHIMMER_SCALE}px ${shimmerContainerRect.height}px`
          : undefined,
        backgroundPosition,
      }}
    >
      {children}
    </motion.div>
  )
}
