import { useObservable } from "@utils/useObservable"
import { di } from "@di"

export const usePreferences = () => {
  const preferences = useObservable(di.PreferencesService.preferences, di.PreferencesService.preferences.value)

  return {
    setPreferences: di.PreferencesService.setPreferences,
    ...preferences,
  }
}
