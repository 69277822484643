import { useGraphQLQuery } from "@data/useGraphQL"
import { DropDownList } from "@ds"
import { cabinetsQuery } from "./graphql/cabinetsQuery"
import { usePreferences } from "@infra/preferences/usePreferences"
import { useNavigate } from "@tanstack/react-router"
import { chargementPageRoute } from "@infra/navigation/router"
import { useEffect } from "react"

export const SelecteurDeCabinet = () => {
  const { identifiantDuCabinet, setPreferences } = usePreferences()
  const navigate = useNavigate()

  const { data } = useGraphQLQuery({ document: cabinetsQuery })

  const items = data?.data?.cabinets.map(({ id, nom }) => ({ id, name: nom }))
  const cabinetPrefere = items?.find(({ id }) => id == identifiantDuCabinet)

  const cabinetPrefereId = cabinetPrefere?.id

  useEffect(() => {
    if (items == undefined) {
      return
    }
    if (cabinetPrefereId == undefined) {
      navigate({ to: chargementPageRoute.to })
      return
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cabinetPrefereId, navigate])

  if (cabinetPrefere == undefined) {
    return
  }

  return (
    <DropDownList
      selectedItem={cabinetPrefere}
      setSelectedItem={({ id }) => setPreferences({ identifiantDuCabinet: id })}
      items={items ?? []}
    />
  )
}
