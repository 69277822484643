import { RechercherVaccinsQueryQuery, VaccinInput } from "@data/gql/graphql"
import { rechercherVaccinsQuery } from "@data/preconsultation/queries/rechercherVaccins"
import { useGraphQLQuery } from "@data/useGraphQL"
import { Bin, classNames, ChevronDown, ChevronUp, Cross, Vaccine } from "@ds"
import { Combobox, ComboboxInput, ComboboxOption, ComboboxOptions, Field, Input, Label } from "@headlessui/react"
import { isAfter, parse, subDays } from "date-fns"
import { Dispatch, SetStateAction, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useToggle } from "usehooks-ts"

export type Indexable = {
  index: number
}

export type IndexedVaccin = Indexable & Partial<VaccinInput>

export type ReferenceVaccin = RechercherVaccinsQueryQuery["vaccins"][number]

type FormVaccinProps = {
  vaccin: IndexedVaccin
  mettreAJour: (vaccin: IndexedVaccin) => void
  supprimer: (index: number) => void
}

export const FormVaccin = ({ vaccin, mettreAJour, supprimer }: FormVaccinProps) => {
  const { t } = useTranslation()
  const [vaccinMisAJour, setVaccinMisAJour] = useState<IndexedVaccin>(vaccin)
  const [dateDExpiration, setDateDExpiration] = useState(vaccinMisAJour.dateDExpiration)
  const [error, setError] = useState(false)
  const [isToggled, toggle] = useToggle(vaccin?.id !== undefined ? false : true)

  return (
    <Field className={"p-2 text-p-small text-black odd:border-r"}>
      <div className="group flex min-w-0 flex-1 justify-between rounded-xl p-2 hover:cursor-pointer hover:bg-extra-light-grey">
        <div
          className="flex min-w-0 items-center gap-1 hover:cursor-pointer"
          onClick={toggle}
          data-testid="label-vaccin"
        >
          <Vaccine />
          <Label className="truncate hover:cursor-pointer">
            {t("vaccin")} {vaccinMisAJour.nom}
          </Label>

          {isToggled ? <ChevronDown /> : <ChevronUp />}
        </div>

        <div
          className="rounded-md border border-transparent p-1 text-transparent hover:border hover:border-grey hover:text-grey group-hover:text-black"
          data-testid={"delete-" + vaccinMisAJour.id}
          onClick={() => supprimer(vaccinMisAJour.index)}
        >
          <Bin />
        </div>
      </div>

      {isToggled && (
        <div className="flex flex-col gap-2 p-2 text-p-small" data-testid="form-vaccin">
          <RechercheNomVaccin
            value={{ id: vaccinMisAJour.idDoctolib, nom: vaccinMisAJour.nom }}
            onSelect={setVaccinMisAJour}
            mettreAJour={() => mettreAJour(vaccinMisAJour)}
          />
          <Input
            data-testid="input-numeroDeLot-vaccin"
            title={t("numeroDeLot")}
            className="border-box rounded bg-extra-light-grey p-2 outline-none"
            placeholder={t("numeroDeLot")}
            value={vaccinMisAJour.numeroDeLot || ""}
            onChange={(e) => setVaccinMisAJour({ ...vaccinMisAJour, numeroDeLot: e.target.value })}
            onBlur={() => {
              mettreAJour(vaccinMisAJour)
            }}
          />

          <Input
            type={dateDExpiration ? "date" : "text"}
            title={t("dateDExpiration")}
            className={classNames("border-box flex-1 rounded bg-extra-light-grey p-2 outline-none", {
              "border-2 border-error": error,
            })}
            placeholder={t("dateDExpiration")}
            value={vaccinMisAJour.dateDExpiration || ""}
            onChange={(e) => {
              setDateDExpiration(e.target.value)
              setVaccinMisAJour({ ...vaccinMisAJour, dateDExpiration: e.target.value })
            }}
            onFocus={(e) => {
              e.target.type = "date"
            }}
            onBlur={() => {
              if (
                dateDExpiration &&
                isAfter(parse(dateDExpiration, "yyyy-MM-dd", new Date()), subDays(new Date(), 1))
              ) {
                setError(false)
                mettreAJour(vaccinMisAJour)
              } else {
                setError(true)
              }
            }}
          />
        </div>
      )}
    </Field>
  )
}

type RechercheNomVaccinProps = {
  value?: Partial<ReferenceVaccin>
  onSelect: Dispatch<SetStateAction<IndexedVaccin>>
  mettreAJour?: () => void
}

const RechercheNomVaccin = ({ value, onSelect, mettreAJour }: RechercheNomVaccinProps) => {
  const { t } = useTranslation()
  const [selectedVaccin, setSelectedVaccin] = useState<Partial<ReferenceVaccin> | undefined>(value)
  const [recherche, setRecherche] = useState("")
  const [error, setError] = useState(false)
  const { data } = useGraphQLQuery({
    document: rechercherVaccinsQuery,
    variables: { recherche },
    enabled: recherche?.length > 0,
  })

  const referencesVaccins = data?.data?.vaccins?.sort((a, b) => a.nom.localeCompare(b.nom)) ?? []

  const onClose = () => {
    setRecherche("")
    setSelectedVaccin({})
    setError(true)
    onSelect((vaccin) => ({ ...vaccin, idDoctolib: "", nom: "" }))
  }

  useEffect(() => {
    if (selectedVaccin?.id) {
      setError(false)
    }
  }, [selectedVaccin])

  return (
    <div onBlur={mettreAJour}>
      <Combobox
        value={selectedVaccin}
        onChange={(e) => {
          if (e) setSelectedVaccin(e)
          onSelect((vaccin) => ({ ...vaccin, idDoctolib: e?.id || "", nom: e?.nom || "" }))
          if (mettreAJour) mettreAJour()
        }}
      >
        <div
          className={classNames("border-box group flex w-full justify-between gap-1 rounded bg-extra-light-grey p-2", {
            "border-2 border-error": error,
          })}
        >
          <ComboboxInput
            className="flex flex-1 truncate bg-extra-light-grey outline-none"
            data-testid="input-nom-vaccin"
            aria-label="vaccin-choisi"
            displayValue={() => selectedVaccin?.nom || ""}
            onChange={(event) => setRecherche(event.target.value)}
            placeholder={t("nomDuVaccin")}
            autoComplete="off"
          />
          <button data-testid="close" onClick={onClose} className="text-transparent group-hover:text-black">
            <Cross className="text-p-medium" />
          </button>
        </div>

        <ComboboxOptions
          anchor="bottom start"
          className={classNames(
            "ml-[-8px] mt-2 overflow-scroll truncate rounded-xl bg-white p-3 text-p-tiny shadow-light-shadow empty:hidden",
            { "h-36": referencesVaccins.length > 5 },
          )}
        >
          {referencesVaccins.map((vaccin) => (
            <ComboboxOption
              data-testid={"option-" + vaccin.id}
              key={vaccin.id}
              value={vaccin}
              className="cursor-pointer rounded-md p-1 text-p-small hover:bg-extra-light-grey"
            >
              {vaccin.nom}
            </ComboboxOption>
          ))}
        </ComboboxOptions>
      </Combobox>
    </div>
  )
}
