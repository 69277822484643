import { ChevronDown, ChevronUp } from "@ds"
import { Listbox, ListboxButton, ListboxOption, ListboxOptions } from "@headlessui/react"
import { useTranslation } from "react-i18next"

type FiltreMedecinProps = {
  medecins: { id: string; name: string }[]
  selection: {
    id: string
    name: string
  }[]
  setSelection: (value: { id: string; name: string }[]) => void
}

export const FiltreMedecin = ({ medecins, selection, setSelection }: FiltreMedecinProps) => {
  const { t } = useTranslation()

  return (
    <div data-testid="filtre-medecins">
      <Listbox value={selection} onChange={setSelection} multiple disabled={medecins?.length == 0}>
        {({ open }) => (
          <>
            <ListboxButton className="group w-52">
              <div className="flex w-full items-center justify-between gap-2 rounded-md border border-transparent bg-extra-light-grey px-3 py-2 text-h6 group-hover:border-black group-hover:bg-white">
                <div data-testid={"selected-placeholder"} className="truncate text-left text-h6">
                  {selection
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .map((item) => item?.name)
                    .filter((item) => item != undefined)
                    .join(", ") || t("tousLesMedecins")}
                </div>
                <div className="text-[16px]">{open ? <ChevronUp /> : <ChevronDown />}</div>
              </div>
            </ListboxButton>
            <div className="relative">
              <ListboxOptions className="absolute top-1 max-h-64 w-full gap-1 overflow-auto rounded-xl bg-white p-3 shadow-light-shadow">
                {medecins
                  .sort((a, b) => a.name.localeCompare(b.name))
                  .map((item) => (
                    <ListboxOption
                      key={item.id}
                      data-testid={"medecin-option-" + item.id}
                      value={item}
                      className="flex cursor-pointer items-center gap-2 rounded-lg px-3 py-2 text-p-tiny ui-selected:bg-extra-light-grey ui-active:bg-extra-light-grey ui-not-active:bg-inherit"
                    >
                      {({ selected }) => (
                        <>
                          <input
                            type="checkbox"
                            checked={selected}
                            className="outline-1 ui-selected:accent-dark-plum"
                            onChange={() => {}} // This is needed to prevent a warning
                          />
                          <div className="truncate ui-selected:text-dark-plum">{item.name}</div>
                        </>
                      )}
                    </ListboxOption>
                  ))}
              </ListboxOptions>
            </div>
          </>
        )}
      </Listbox>
    </div>
  )
}
