import { di } from "@di"
import { Shimmer } from "@ds"
import { useObservable } from "@utils/useObservable"
import { AnimatePresence, motion } from "framer-motion"
import { useTranslation } from "react-i18next"

export interface UpdateAvailableCardProps {
  className: string
}

export const UpdateAvailableCard = ({ className }: UpdateAvailableCardProps) => {
  const { t } = useTranslation()
  const { updateService } = di
  const canUpdate = useObservable(updateService.canUpdate, false)
  return (
    <AnimatePresence>
      {canUpdate && (
        <motion.div
          initial={{ opacity: 0, scale: 0.5, y: 50 }}
          animate={{ opacity: 1, scale: 1, y: 0 }}
          exit={{ opacity: 0, scale: 0.5, y: 50 }}
          className={className}
        >
          <div className="text-h5">{t("nouvelleVersion")}</div>
          <button className="relative rounded-md bg-yellow p-2 text-links-small" onClick={updateService.update}>
            {t("mettreAJour")}
            <Shimmer className="absolute bottom-0 left-0 right-0 top-0" />
          </button>
        </motion.div>
      )}
    </AnimatePresence>
  )
}
