import { useParticipants } from "@features/messagerie/hooks/useParticipants.tsx"
import { DefaultStreamChatGenerics, MessageInput, MessageList, Window } from "stream-chat-react"
import { usePatientDuChannel } from "@features/messagerie/hooks/usePatientDuChannel.ts"
import { HeaderWithTools } from "@features/messagerie/HeaderWithTools.tsx"
import { CustomMessage } from "@features/messagerie/CustomMessage.tsx"
import { Warning } from "@ds"
import { CustomMessageInput } from "@features/messagerie/CustomMessageInput.tsx"
import { useTranslation } from "react-i18next"
import { Channel } from "stream-chat"

interface ChannelInnerProps {
  channel: Channel<DefaultStreamChatGenerics>
  setAfficherConfirmation: (value: boolean) => void
}

export const ChannelInner = ({ setAfficherConfirmation, channel }: ChannelInnerProps) => {
  const { updateParticipants, ModaleDeConfirmationDeRetrait } = useParticipants()
  const maybeIdProfilPatient = channel?.data?.idProfilPatient
  if (!maybeIdProfilPatient || typeof maybeIdProfilPatient !== "string") {
    throw new Error("idProfilPatient is required")
  }
  const { patient, nonOnboarde, profilPrincipal } = usePatientDuChannel(maybeIdProfilPatient)

  return (
    <Window>
      <ModaleDeConfirmationDeRetrait />
      <HeaderWithTools
        patient={patient}
        nonOnboarde={nonOnboarde}
        profilPrincipal={profilPrincipal}
        onClickArchiver={() => setAfficherConfirmation(true)}
        updateParticipants={updateParticipants}
      />
      <MessageList Message={CustomMessage} showUnreadNotificationAlways={false} />
      {nonOnboarde && <NonOnboarde />}
      <MessageInput
        Input={() => <CustomMessageInput onClickReactiver={() => setAfficherConfirmation(true)} />}
        publishTypingEvent={true}
      />
    </Window>
  )
}

const NonOnboarde = () => {
  const { t } = useTranslation()

  return (
    <div className="flex w-full justify-start">
      <div className="flex items-center gap-1">
        <Warning className="h-5 w-5 text-grey" />
        <div className="text-p-tiny text-grey" data-testid="no-app-message">
          {t("messagerie.patientNonOnboarde")}
        </div>
      </div>
    </div>
  )
}
