import { rendezVousParIdentifiantQuery } from "@data/rendezVous/queries/rendezVousParIdentifiantQuery"
import { useGraphQLQuery } from "@data/useGraphQL"
import { SidePanel } from "@ds"
import { preconsultationRoute } from "@infra/navigation/router"
import { useNavigate } from "@tanstack/react-router"
import { useTranslation } from "react-i18next"

export const QuestionnairePanel = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { id: identifiantPreconsultation } = preconsultationRoute.useParams()
  const close = () => navigate({ to: preconsultationRoute.to, params: { id: identifiantPreconsultation } })
  const { data } = useGraphQLQuery({
    document: rendezVousParIdentifiantQuery,
    variables: { identifiant: identifiantPreconsultation },
  })
  const questionnaire = data?.data?.rendezVousParIdentifiant?.preconsultation.preparation.questionnaire || []

  return (
    <SidePanel onClose={close}>
      <div className="flex flex-col items-start gap-2">
        <h1 className="flex-1 overflow-hidden text-ellipsis text-h1">{t("preparationPatient")}</h1>
      </div>
      <div className="h-10" />
      {questionnaire
        .filter(({ libelleQuestion }) => libelleQuestion != "")
        .map(({ identifiant, libelleQuestion, libelleReponse }, index, list) => (
          <div key={identifiant} className="flex flex-col">
            <h4 className="text-h4">{libelleQuestion}</h4>
            <div className="h-4" />
            <p className="text-p-medium">{libelleReponse}</p>
            {index < list.length - 1 && <div className="m-6 h-[1px] self-stretch bg-extra-light-grey" />}
          </div>
        ))}
    </SidePanel>
  )
}
