import { PatientParIdentifiantQueryQuery, StatutProchainDepistage } from "@data/gql/graphql"
import { ArrowLeft, Avatar, classNames, Doctolib, Dot, EyeFilled, PrimaryApp } from "@ds"
import { agePatient } from "@features/patient/agePatient"
import { contextePatientPanelRoute } from "@infra/navigation/router"
import { useNavigate } from "@tanstack/react-router"
import { capitalize } from "@utils/capitalize"
import { useFlags } from "launchdarkly-react-client-sdk"
import { ReactNode } from "react"
import { useTranslation } from "react-i18next"

interface EntetePatientProps {
  patient: PatientParIdentifiantQueryQuery["patientParIdentifiant"]
  onBack?: () => void
  info?: ReactNode
  noBorder?: boolean
  showContexte?: boolean
}

export const EntetePatient = ({ patient, onBack, info, noBorder, showContexte }: EntetePatientProps) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const patientPossedeLApplication = patient.compte
  const { visualisationDepistagesConsole } = useFlags()
  const depistagesAFaire = patient.depistages.some(
    (depistage) =>
      depistage.statutProchainDepistage === StatutProchainDepistage.AFaire ||
      depistage.statutProchainDepistage === StatutProchainDepistage.EnRetard,
  )

  return (
    <div
      className={classNames("flex flex-col gap-4", noBorder ? "pt-6" : "border-b border-light-grey py-6")}
      data-testid={"entete-patient"}
    >
      <div className="flex items-center gap-4">
        <div className="cursor-pointer py-1 text-2xl text-black" onClick={onBack} data-testid="back-to-arrow">
          <ArrowLeft />
        </div>
        <Avatar name={patient.prenom + " " + patient.nom} />

        <span className="text-h2">{capitalize(patient?.prenom) + " " + patient.nom}</span>
        {info && info}
      </div>
      <div className="flex items-center gap-2">
        <span className="text-h5">{patient.sexe && t(patient.sexe)}</span>
        {patient.dateDeNaissance && (
          <>
            <Dot />
            <span className="text-h5">{patient.dateDeNaissance && agePatient(patient.dateDeNaissance, t)}</span>
          </>
        )}
        {patient.compte && patient.numeroDeTelephone && (
          <>
            <Dot />
            <span className="text-h5">{patient.numeroDeTelephone}</span>
          </>
        )}
        {patientPossedeLApplication && (
          <>
            <Dot />
            <PrimaryApp />
          </>
        )}
        {patient.lienDoctolib && patient.lienDoctolib.trim() !== "" && (
          <>
            <Dot />
            <a data-testid="lien-doctolib-patient" target="_blank" href={patient.lienDoctolib}>
              <Doctolib className="text-h3" />
            </a>
          </>
        )}
        {visualisationDepistagesConsole && depistagesAFaire && (
          <>
            <Dot />
            <div
              className={classNames("flex gap-2", showContexte && "hover:cursor-pointer")}
              onClick={() =>
                showContexte &&
                navigate({ to: contextePatientPanelRoute.to, params: { identifiantProfil: patient.id } })
              }
            >
              <div className="text-h5 text-error">{t("depistagesAFaire")}</div>
            </div>
          </>
        )}
        {showContexte && (
          <>
            <Dot />
            <div
              className="flex gap-2 hover:cursor-pointer"
              onClick={() => navigate({ to: contextePatientPanelRoute.to, params: { identifiantProfil: patient.id } })}
            >
              <div className="text-h5">{t("voirLeProfil")}</div>
              <EyeFilled color="bg-dark-plum" />
            </div>
          </>
        )}
      </div>
    </div>
  )
}
