import { BehaviorSubject } from "rxjs"
import { IPreferencesService, Preferences } from "./IPreferencesService"

const initialPreferences = {
  identifiantDuCabinet: null,
  sideBarOuverte: true,
  filtreInboxMessagerie: "ma-boite",
  filtreParticipantsMessagerie: [],
  messagesATraiterUniquement: false,
}

export class PreferencesService implements IPreferencesService {
  preferences: BehaviorSubject<Preferences>

  constructor() {
    let localStoragePreferences = initialPreferences

    try {
      const localStoragePreferencesString = localStorage.getItem("preferences")
      if (localStoragePreferencesString) {
        localStoragePreferences = JSON.parse(localStoragePreferencesString)
      }
    } catch (error) {
      console.error("Error parsing JSON:", error)
    }

    this.preferences = new BehaviorSubject<Preferences>({
      ...initialPreferences,
      ...localStoragePreferences,
    })
  }

  setPreferences = (nouvellesPreferences: Partial<Preferences>) => {
    const preferences = this.preferences.value
    const newPreferences = { ...preferences, ...nouvellesPreferences }
    localStorage.setItem("preferences", JSON.stringify(newPreferences))
    this.preferences.next(newPreferences)
  }
}
