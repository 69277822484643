import { PatientParIdentifiantQueryQuery, StatutDepistage, StatutProchainDepistage } from "@data/gql/graphql"
import { ChevronDown, ChevronUp, EnteteTag, Subtract, WarningSolid } from "@ds"
import { useTranslation } from "react-i18next"
import { getYear } from "date-fns"
import { useState } from "react"

export type CardDepistageProps = {
  depistage: PatientParIdentifiantQueryQuery["patientParIdentifiant"]["depistages"][0]
}

export const CardDepistage = ({ depistage }: CardDepistageProps) => {
  const { t } = useTranslation()
  const [ouverte, setOuverte] = useState(
    depistage.statutProchainDepistage === StatutProchainDepistage.AFaire ||
      depistage.statutProchainDepistage === StatutProchainDepistage.EnRetard,
  )

  const dateDernierDepistage = () => {
    switch (depistage.statutDernierDepistage) {
      case StatutDepistage.Fait:
        return depistage.dateDernierDepistage ? getYear(depistage.dateDernierDepistage) : t("dateInconnue")
      case StatutDepistage.NeSaitPas:
      case StatutDepistage.NonFait:
        return t("jamaisFait")
      case null:
        return t("nonRenseigne")
    }
  }

  const aRisque = () => {
    switch (depistage.statutDernierDepistage) {
      case StatutDepistage.PasARisque:
        return t("non")
      case null:
        return t("nonRenseigne")
      default:
        return t("oui")
    }
  }

  return (
    <div
      className="flex flex-col gap-2 rounded-xl p-4 shadow-very-light-shadow"
      data-testid={"depistage-" + depistage.id}
    >
      <div className="flex justify-between">
        <div className="flex cursor-pointer gap-1" onClick={() => setOuverte(!ouverte)}>
          {ouverte && <ChevronUp />}
          {!ouverte && <ChevronDown />}
          <div className="text-h4">{depistage.nom}</div>
        </div>
        <TagStatut statut={depistage.statutProchainDepistage} />
      </div>
      {ouverte && (
        <div className="flex flex-col">
          {depistage.evaluationDuRisque && (
            <>
              <LigneDepistage libelle={depistage.evaluationDuRisque} valeur={aRisque()} testId="evaluationDuRisque" />
              <LigneDepistage
                libelle={depistage.questionDepistage}
                valeur={depistage.statutDernierDepistage === StatutDepistage.Fait ? t("oui") : t("non")}
                testId="questionDepistage"
              />
            </>
          )}

          {depistage.questionDateDernierExamen && (
            <LigneDepistage
              libelle={t("dernierDepistage")}
              valeur={dateDernierDepistage()}
              testId="dateDernierDepistage"
            />
          )}

          {!depistage.evaluationDuRisque && depistage.dateDernierDepistage && (
            <LigneDepistage
              libelle={t("prochainDepistage")}
              valeur={getYear(depistage.dateProchainDepistage)}
              testId="prochainDepistage"
            />
          )}

          {depistage.description && (
            <div className="flex max-w-lg items-center justify-between gap-2" data-testid="description">
              <div className="px-2 py-1 text-p-tiny text-dark-grey">{depistage.description}</div>
            </div>
          )}
          {depistage.examen && (
            <div className="flex max-w-lg items-center justify-between gap-2" data-testid="conditions">
              <div
                className="px-2 py-1 text-p-tiny text-dark-grey"
                // à cause de bilan sanguin & prélèvement
                dangerouslySetInnerHTML={{ __html: t("examen", { nom: depistage.examen }) }}
              ></div>{" "}
            </div>
          )}
        </div>
      )}
    </div>
  )
}

const LigneDepistage = ({
  libelle,
  valeur,
  testId,
}: {
  libelle: string
  valeur: string | number | undefined
  testId: string
}) => {
  return (
    <div className="ml-6 flex max-w-lg items-center justify-between gap-2" data-testid={testId}>
      <div className="px-2 py-1 text-p-tiny">{libelle}</div>
      <div className="px-2 py-1 text-h6 text-dark-plum">{valeur}</div>
    </div>
  )
}

const TagStatut = ({ statut }: { statut: StatutProchainDepistage }) => {
  const { t } = useTranslation()
  switch (statut) {
    case StatutProchainDepistage.AFaire:
      return (
        <EnteteTag
          icon={<WarningSolid className="text-h7 text-error" />}
          content={t("aFaire")}
          color={"bg-extra-light-grey"}
          upperCase={false}
        />
      )
    case StatutProchainDepistage.AJour:
      return (
        <EnteteTag
          icon={<Subtract className="text-h7 text-valid" />}
          content={t("aJour")}
          color={"bg-secondary-green"}
          upperCase={false}
        />
      )
    case StatutProchainDepistage.EnRetard:
      return (
        <EnteteTag
          icon={<WarningSolid className="text-h7 text-error" />}
          content={t("enRetard")}
          color={"bg-secondary-red"}
          upperCase={false}
        />
      )
    case StatutProchainDepistage.NonRenseigne:
      return <EnteteTag content={t("nonRenseigne")} color={"bg-extra-light-grey"} upperCase={false} />
  }
}
