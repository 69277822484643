import { classNames } from "../classNames"
import { ReactNode } from "react"

type EnteteTagProps = {
  color: string
  content: string
  icon?: ReactNode
  upperCase?: boolean
}

export const EnteteTag = ({ color, content, icon, upperCase = true }: EnteteTagProps) => (
  <div className={classNames("flex h-5 items-center gap-1 rounded px-2 py-1 align-middle text-h7", color)}>
    {icon && icon}
    <div>{upperCase ? content.toUpperCase() : content}</div>
  </div>
)
