import { UpdateAvailableCardProps } from "@features/consoleUpdates/UpdateAvailableCard"
import { IStreamChatService } from "@features/messagerie/infra/out/IStreamChatService"
import { Analytics } from "@infra/analytics/Analytics"
import { IAuthService } from "@infra/auth/IAuthService"
import { UseAuth } from "@infra/auth/useAuth"
import { IPreferencesService } from "@infra/preferences/IPreferencesService"
import { IGraphQLQueryClient } from "@infra/queryClient/GraphQLQueryClient"
import { UpdateService } from "@infra/updates/UpdateService"
import { ComponentType } from "react"

interface DI {
  UnreadCount: ComponentType
  SelecteurDeCabinet: ComponentType
  RecherchePatientModal: ComponentType
  analytics: Analytics
  useAuth: UseAuth
  UserProfile: ComponentType
  UpdateAvailableCard: (props: UpdateAvailableCardProps) => JSX.Element
  register: (di: Partial<DI>) => unknown
  updateServiceWorker: () => void
  updateService: UpdateService
  AuthService: IAuthService
  GraphQLQueryClient: IGraphQLQueryClient
  PreferencesService: IPreferencesService
  StreamChatService: IStreamChatService
}

// @ts-expect-error("")
const registry: DI = {}

export const register = (di: Partial<DI>) => {
  Object.assign(registry, di)
}

const handler: ProxyHandler<DI> = {
  get(target, property) {
    // @ts-expect-error("")
    if (target[property]) {
      // @ts-expect-error("")
      return target[property]
    }
    throw new Error(`DI: ${String(property)} not registered`)
  },
}

export const di = new Proxy<DI>(registry, handler)
