import { Shimmer } from "../animation/Shimmer"
import { classNames } from "../classNames"
import { ReactNode } from "react"

type SubmitButtonProps = {
  title?: string
  onClick?: () => unknown
  icon?: ReactNode
  type: "filled" | "tonal" | "outlined"
  disabled?: boolean
  loading?: boolean
}

export const SubmitButton = ({ title, onClick, icon, type, disabled, loading }: SubmitButtonProps) => {
  const disabledOrLoading = disabled || loading
  return (
    <button
      data-testid={`submit-button-${title}`}
      className={classNames(
        "relative flex w-64 items-center justify-center gap-2 rounded-xl px-12 py-4 text-links-small",
        type === "filled" && ["bg-yellow", !disabledOrLoading && "hover:bg-yellow-hover"],
        type === "tonal" && ["bg-dark-plum text-white", !disabledOrLoading && "hover:bg-dark-plum-hover"],
        type === "outlined" && [
          "border border-dark-plum bg-white text-dark-plum",
          !disabledOrLoading && "hover:border-dark-plum-hover hover:text-dark-plum-hover",
        ],
        disabledOrLoading && "opacity-70",
      )}
      disabled={disabled || loading}
      onClick={onClick}
    >
      {title && <div>{title}</div>}
      {icon && <div>{icon}</div>}
      {loading && <Shimmer show className="absolute bottom-0 left-0 right-0 top-0" />}
    </button>
  )
}
