import { Menu, MenuButton, MenuItems, MenuItem, Input, MenuSeparator } from "@headlessui/react"
import { classNames, ListViewCircle } from "@ds"
import { useGraphQLQuery } from "@data/useGraphQL.ts"
import { reponsesRapidesQuery } from "@data/messagerie/reponsesRapidesQuery.ts"
import { usePreferences } from "@infra/preferences/usePreferences.tsx"
import { useTranslation } from "react-i18next"
import { useState } from "react"

export interface ReponseRapideProps {
  text: string
  setText: (text: string) => void
  className?: string
}

export const ReponsesRapides = ({ text, setText, className }: ReponseRapideProps) => {
  const { t } = useTranslation()
  const { identifiantDuCabinet } = usePreferences()
  const [filter, setFilter] = useState("")
  const { data: reponsesRapides } = useGraphQLQuery({
    document: reponsesRapidesQuery,
    variables: { identifiantCabinet: identifiantDuCabinet! },
    enabled: identifiantDuCabinet !== undefined,
  })

  if (!reponsesRapides?.data?.reponsesRapides?.length) {
    return null
  }

  const reponsesRapidesFiltrees = reponsesRapides.data.reponsesRapides.filter((r) => {
    return (
      normalize(r.titre.toLowerCase()).includes(normalize(filter)) ||
      normalize(r.contenu.toLowerCase()).includes(normalize(filter))
    )
  })
  return (
    <Menu>
      <MenuButton
        data-testid="reponses-rapides"
        className={classNames(
          "flex items-center justify-center rounded bg-extra-light-grey hover:opacity-60 active:opacity-100",
          className,
        )}
      >
        <ListViewCircle className="text-h3 text-gray-400" />
      </MenuButton>
      <MenuItems
        onFocus={(e) => {
          e.stopPropagation()
          document.getElementById("reponse-rapides-filter-input")?.focus()
        }}
        transition
        anchor="bottom start"
        className="flex origin-bottom-left flex-col items-stretch rounded-lg bg-white p-3 shadow-drop-shadow transition duration-200 ease-out data-[closed]:scale-95 data-[closed]:opacity-0"
      >
        {reponsesRapidesFiltrees.map((reponseRapide, index) => (
          <MenuItem key={"reponse-rapide-" + index}>
            <button
              className="rounded-lg p-2 text-left hover:bg-extra-light-grey data-[focus]:bg-extra-light-grey"
              onClick={() => setText(text + reponseRapide.contenu)}
            >
              <div className="selected:text-dark-plum truncate">{reponseRapide.titre}</div>
            </button>
          </MenuItem>
        ))}
        {reponsesRapidesFiltrees.length == 0 && (
          <MenuItem key="aucun-resultat" disabled>
            <div className="p-2 text-grey">{t("messagerie.aucuneReponseRapide")}</div>
          </MenuItem>
        )}
        <MenuSeparator key="separator" className="my-1 h-px bg-extra-light-grey" />
        <Input
          key="filter"
          placeholder={t("messagerie.filtrer")}
          id="reponse-rapides-filter-input"
          className="mt-2 w-full p-2 outline-none"
          onChange={(e) => {
            setFilter(e.target.value.toLowerCase())
          }}
        />
      </MenuItems>
    </Menu>
  )
}

const normalize = (str: string) =>
  str
    .toLocaleLowerCase()
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
