import React, { SVGProps } from "react"
import { classNames } from "../classNames"

export interface ToolButtonProps {
  onClick?: () => void
  title: string
  icon: (props: SVGProps<SVGSVGElement>) => React.ReactNode
  dot?: number
  type?: "filled" | "tonal" | "outlined"
  dataTestId?: string
  className?: string
}

export const ToolButton = ({
  onClick,
  title,
  icon: Icon,
  dot,
  type = "filled",
  dataTestId,
  className,
}: ToolButtonProps) => {
  return (
    <>
      <div
        className={classNames(
          "flex h-6 w-6 cursor-pointer items-center justify-center rounded",
          type === "outlined" ? "bg-extra-light-grey" : "",
          type === "tonal" ? "bg-dark-plum" : "",
          type === "filled" ? "bg-yellow" : "",
          className,
        )}
        title={title}
        onClick={onClick}
        data-testid={dataTestId}
      >
        {type === "outlined" && <Icon className="text-h5 text-grey" />}
        {(type === "tonal" || type === "filled") && <Icon className="text-h5 text-white" />}
      </div>
      {dot && (
        <div className="relative" data-testid="dot">
          <div className="absolute bottom-[-4px] right-[-4px] h-4 w-4 rounded-full bg-light-grey p-[2px] text-h7 text-black">
            {dot}
          </div>
        </div>
      )}
    </>
  )
}
