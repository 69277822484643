import { classNames } from "../classNames"
import { ReactNode } from "react"

type PlainButtonProps = {
  title?: string
  icon?: ReactNode
  onClick?: () => void
  type?: "filled" | "outlined"
  className?: string
  children?: ReactNode
}

export const PlainButton = ({ title, icon, onClick, type, className, children }: PlainButtonProps) => {
  return (
    <button
      className={classNames(
        "flex items-center gap-2 rounded-md px-5 py-2 text-h6",
        {
          //TODO: ajouter un état hover pour les différentes déclinaisons (discuter avec Maud)
          "bg-extra-light-grey": type === "filled",
          "border bg-white": type === "outlined",
        },
        className,
      )}
      onClick={onClick}
    >
      {children ? (
        children
      ) : (
        <>
          <div>{title}</div>
          {icon && <div>{icon}</div>}
        </>
      )}
    </button>
  )
}
