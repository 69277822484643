import { Auth0Provider, useAuth0 } from "@auth0/auth0-react"
import { di } from "@di"

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  return (
    <Auth0Provider
      domain={import.meta.env.VITE_AUTH0_DOMAIN}
      clientId={import.meta.env.VITE_AUTH0_CLIENT_ID}
      authorizationParams={{
        redirect_uri: window.location.origin,
      }}
      useRefreshTokens
      cacheLocation="localstorage"
    >
      <AuthProxy>{children}</AuthProxy>
    </Auth0Provider>
  )
}

const AuthProxy = ({ children }: { children: React.ReactNode }) => {
  const auth0 = useAuth0()

  di.AuthService.updateFromAuth0Interface(auth0)

  return children
}
