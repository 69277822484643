import { BehaviorSubject, throttleTime, asyncScheduler, skip, take } from "rxjs"
import { SyntheseAPI, rendezVousParIdentifiantQuery } from "@data/rendezVous/queries/rendezVousParIdentifiantQuery"
import { MDXEditor, listsPlugin, headingsPlugin, markdownShortcutPlugin, MDXEditorMethods } from "@mdxeditor/editor"
import "@mdxeditor/editor/style.css"
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react"
import { useGQLMutation } from "@data/useGraphQL"
import { enregistrerSynthese } from "@data/preconsultation/mutations/enregistrerSynthese"
import { useTranslation } from "react-i18next"
import { classNames, Shimmer } from "@ds"
import { useFlags } from "launchdarkly-react-client-sdk"
import { DonneesDeSaisiesPatient } from "./DonneesDeSaisiesPatient"

export interface SyntheseMethods {
  setMarkdown: (markdown: string) => void
  getMarkdown: () => string
}

type SyntheseProps = {
  rendezVousId: string
  synthese: Pick<SyntheseAPI, "contenu" | "dateDeMiseAJour" | "miseAJourPar"> | null | undefined
  throttleDuration?: number
  toolbar?: (
    markdown: string,
    setMarkdown: (markdown: string) => unknown,
    setDisableInput: (disable: boolean) => unknown,
  ) => JSX.Element
  sidebar?: (markdown: string) => JSX.Element
  className?: string
  onEditionStarted?: () => unknown
}

export const Synthese = forwardRef<SyntheseMethods, SyntheseProps>(
  ({ synthese, toolbar, rendezVousId, throttleDuration, className, sidebar, onEditionStarted }, ref) => {
    const initialMarkdown = makeFirstLineHeadline(synthese?.contenu)
    const { t } = useTranslation()
    const [markdown, setMarkdown] = useState<string>(initialMarkdown)
    const [disable, setDisable] = useState<boolean>(false)
    const [markdownSubject] = useState<BehaviorSubject<string>>(new BehaviorSubject(initialMarkdown))
    const { mutate } = useGQLMutation(enregistrerSynthese, {
      invalidateQueryKeys: [[rendezVousParIdentifiantQuery, { identifiant: rendezVousId }]],
    })
    const { donneesBiometriquesFcPasPadT } = useFlags()

    useEffect(() => {
      //on considère que l'édition commence réellement après 5 touches
      const subscription = markdownSubject
        .pipe(skip(5))
        .pipe(take(1))
        .subscribe(() => {
          onEditionStarted?.()
        })
      return () => {
        subscription.unsubscribe()
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
      const subscription = markdownSubject
        .pipe(skip(1))
        .pipe(throttleTime(throttleDuration ?? 1000, asyncScheduler, { leading: true, trailing: true }))
        .subscribe((markdown) => {
          mutate({
            contenu: markdown,
            identifiantDuRendezVous: rendezVousId,
          })
        })
      return () => {
        subscription.unsubscribe()
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const editorRef = useRef<MDXEditorMethods>(null)
    const doSetMarkdown = (markdown: string) => {
      editorRef.current!.setMarkdown(markdown)
      setMarkdown(markdown)
      markdownSubject.next(markdown)
    }
    useImperativeHandle(ref, () => ({
      setMarkdown: doSetMarkdown,
      getMarkdown: () => markdown,
    }))
    return (
      <div className={classNames("flex flex-col", className)}>
        <div className="flex flex-1 items-stretch">
          <div className={classNames("relative mt-4 flex flex-[2] flex-col gap-2")}>
            {disable && <ShimmerLoadingIndicator />}
            <MDXEditor
              readOnly={disable}
              ref={editorRef}
              markdown={markdown}
              placeholder={t("saisissezLaPreconsultation")}
              onChange={(newMarkdown: string) => {
                if (newMarkdown !== markdownSubject.value) {
                  setMarkdown(newMarkdown)
                  markdownSubject.next(newMarkdown)
                }
              }}
              plugins={[listsPlugin(), headingsPlugin({ allowedHeadingLevels: [1, 2, 3] }), markdownShortcutPlugin()]}
              contentEditableClassName="prose prose-h1:text-[1.375rem] prose-h2:text-[1.2rem] prose-h2:mt-0 prose-h3:text-[1.1rem] outline-none max-w-none !p-0 font-sans"
              className={classNames(
                "flex-1 font-sans outline-none transition-opacity",
                disable ? "pointer-events-none opacity-0" : "opacity-100",
              )}
            />
            <div>
              {donneesBiometriquesFcPasPadT && (
                <DonneesDeSaisiesPatient rendezVousId={rendezVousId} onChange={onEditionStarted} />
              )}
            </div>
          </div>
          <div className="mx-4 w-[1px] bg-gradient-to-b from-light-grey to-transparent" />
          <div className="flex-1">{sidebar && sidebar(markdown)}</div>
        </div>
        {toolbar && toolbar(markdown, doSetMarkdown, setDisable)}
      </div>
    )
  },
)

const makeFirstLineHeadline = (contenu: string | undefined | null): string => {
  if (!contenu) return ""
  if (contenu.startsWith("#")) return contenu
  if (contenu.startsWith("-")) return contenu.replace(/- /, "# ")
  return `# ${contenu}`
}

const ShimmerLoadingIndicator = () => {
  return (
    <div className="absolute bottom-0 left-0 right-0 top-0 flex flex-col gap-4">
      <Shimmer className="h-8 w-1/2 rounded bg-extra-light-grey" />
      <Shimmer className="h-6 w-44 max-w-full rounded bg-extra-light-grey" />
      <Shimmer className="h-6 w-72 max-w-full rounded bg-extra-light-grey" />
      <Shimmer className="h-6 w-80 max-w-full rounded bg-extra-light-grey" />
      <Shimmer className="h-6 w-60 max-w-full rounded bg-extra-light-grey" />
      <Shimmer className="h-6 w-44 max-w-full rounded bg-extra-light-grey" />
      <Shimmer className="h-6 w-28 max-w-full rounded bg-extra-light-grey" />
      <Shimmer className="h-6 w-64 max-w-full rounded bg-extra-light-grey" />
      <Shimmer className="h-6 w-56 max-w-full rounded bg-extra-light-grey" />
      <Shimmer className="h-6 w-36 max-w-full rounded bg-extra-light-grey" />
    </div>
  )
}
