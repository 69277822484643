import { TypedDocumentString } from "@data/gql/graphql"
import { IAuthService } from "@infra/auth/IAuthService"
import { QueryClient, QueryKey } from "@tanstack/react-query"
import { ExecutionResult } from "graphql"

export interface IGraphQLQueryClient {
  queryClient: QueryClient
  fetch<TResult, TVariables>(
    document: TypedDocumentString<TResult, TVariables>,
    variables: TVariables | undefined,
    invalidateQueryKeys?: QueryKey[],
  ): Promise<ExecutionResult<TResult>>
  queryFn<TResult, TVariables>(
    document: TypedDocumentString<TResult, TVariables>,
    variables: TVariables | undefined,
    invalidateQueryKeys?: QueryKey[],
  ): Promise<ExecutionResult<TResult>>
}

export class GraphQLQueryClient implements IGraphQLQueryClient {
  constructor(authService: IAuthService) {
    this.#authService = authService
  }

  queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
      },
    },
  })

  #authService: IAuthService

  async fetch<TResult, TVariables>(
    document: TypedDocumentString<TResult, TVariables>,
    variables: TVariables,
    invalidateQueryKeys: QueryKey[] = [],
  ): Promise<ExecutionResult<TResult>> {
    return this.queryClient.fetchQuery({
      queryKey: [document, variables],
      queryFn: () => this.queryFn(document, variables, invalidateQueryKeys),
    })
  }

  async queryFn<TResult, TVariables>(
    document: TypedDocumentString<TResult, TVariables>,
    variables: TVariables,
    invalidateQueryKeys: QueryKey[] = [],
  ): Promise<ExecutionResult<TResult>> {
    const { getIdTokenClaims, getAccessTokenSilently, logout } = this.#authService

    let idToken = await getIdTokenClaims()

    if (!idToken) {
      throw new Error("No idToken")
    }

    if (!idToken.exp) {
      throw new Error("idToken has no expiration date")
    }

    if (idToken.exp - 30 < Date.now() / 1000) {
      try {
        await getAccessTokenSilently({
          cacheMode: "off",
        })
        idToken = await getIdTokenClaims()
      } catch (error) {
        await logout()
        return Promise.reject({ message: "Could not refresh token", error })
      }
    }

    const response = await fetch(import.meta.env.VITE_BACKEND_URL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${idToken?.__raw}`,
      },
      body: JSON.stringify({
        query: document.toString(),
        variables: variables,
      }),
    })

    const result = await response.json()

    await Promise.all(
      invalidateQueryKeys.map((key) =>
        this.queryClient.invalidateQueries({
          queryKey: key,
        }),
      ),
    )

    return result
  }
}
