import { ExecutionResult } from "graphql"
import { useMutation, useQuery, QueryKey, DefaultError, keepPreviousData } from "@tanstack/react-query"
import { TypedDocumentString } from "@data/gql/graphql.ts"
import { ObjMap } from "graphql/jsutils/ObjMap"
import { di } from "@di"

export type UseGraphQLQueryProps<TResult, TVariables> = {
  document: TypedDocumentString<TResult, TVariables>
  variables?: TVariables
  refetchInterval?: number
  staleTime?: number
  keepDataWhileLoading?: boolean
  enabled?: boolean
}

export function useGraphQLQuery<TResult, TVariables>({
  document,
  variables,
  refetchInterval,
  staleTime,
  keepDataWhileLoading,
  enabled,
}: UseGraphQLQueryProps<TResult, TVariables>) {
  return useQuery({
    queryKey: [document, variables],
    queryFn: () => di.GraphQLQueryClient.queryFn(document, variables),
    refetchInterval, //fréquence du refetch
    staleTime, //durée de validité des données
    placeholderData: keepDataWhileLoading ? keepPreviousData : undefined, //permet de ne pas vider le cache pendant le loading
    enabled, //ne lance pas la requête si enabled est false
  })
}

export interface UseGQLMutationOptions<TResult> {
  invalidateQueryKeys?: QueryKey[]
  onSuccess?: (data: TResult | null | undefined) => void
}

export function useGQLMutation<TResult, TVariables>(
  document: TypedDocumentString<TResult, TVariables>,
  options: UseGQLMutationOptions<TResult> = {},
) {
  return useMutation<ExecutionResult<TResult, ObjMap<unknown>>, DefaultError, TVariables>({
    onSuccess: (data) => {
      options.onSuccess?.(data.data)
    },
    mutationFn: (variables: TVariables) =>
      di.GraphQLQueryClient.queryFn(document, variables, options.invalidateQueryKeys),
  })
}
