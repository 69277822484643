import { AppState, Auth0ContextInterface, IdToken, LogoutOptions, RedirectLoginOptions, User } from "@auth0/auth0-react"
import { BehaviorSubject } from "rxjs"
import { IAuthService } from "./IAuthService"

export class AuthService implements IAuthService {
  private notInitialized = () => {
    throw new Error("AuthService not initialized")
  }

  #isAuthenticated: BehaviorSubject<boolean> | undefined
  #id: BehaviorSubject<string | undefined> | undefined

  get isAuthenticated() {
    if (this.#isAuthenticated === undefined) {
      return this.notInitialized()
    }
    return this.#isAuthenticated
  }

  get id() {
    if (this.#id === undefined) {
      return this.notInitialized()
    }
    return this.#id
  }

  loginWithRedirect: (options?: RedirectLoginOptions<AppState>) => Promise<void> = this.notInitialized
  getIdTokenClaims: () => Promise<IdToken | undefined> = this.notInitialized
  logout: (options?: LogoutOptions) => Promise<void> = this.notInitialized
  getAccessTokenSilently: Auth0ContextInterface<User>["getAccessTokenSilently"] = this.notInitialized

  updateFromAuth0Interface(auth0Interface: Auth0ContextInterface<User>) {
    this.loginWithRedirect = auth0Interface.loginWithRedirect
    this.getIdTokenClaims = auth0Interface.getIdTokenClaims
    this.logout = auth0Interface.logout
    this.getAccessTokenSilently = auth0Interface.getAccessTokenSilently

    if (this.#id === undefined) {
      this.#id = new BehaviorSubject(auth0Interface.user?.sub)
    }
    if (this.#isAuthenticated === undefined) {
      this.#isAuthenticated = new BehaviorSubject(auth0Interface.isAuthenticated)
    }

    if (this.#id.value !== auth0Interface.user?.sub) {
      this.#id.next(auth0Interface.user?.sub)
    }

    if (this.#isAuthenticated.value !== auth0Interface.isAuthenticated) {
      this.#isAuthenticated.next(auth0Interface.isAuthenticated)
    }
  }
}
