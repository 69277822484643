import { Archive, Avatar, Collaboration, Doctolib, Flag, ToolButton } from "@ds"
import { useDemandeAideMedecin } from "@features/messagerie/hooks/useDemandeAideMedecin.tsx"
import { PatientDuChannel } from "@features/messagerie/hooks/usePatientDuChannel.ts"
import { ListboxButton } from "@headlessui/react"
import { useFlags } from "launchdarkly-react-client-sdk"
import { useTranslation } from "react-i18next"
import { useChannelStateContext, useChatContext } from "stream-chat-react"
import { Participant, ParticipantProps } from "./Participant"
import { CabinetTag } from "./components/CabinetTag"
import { Soignant } from "./domain/Soignant"
import { DropDownCheckedList } from "./DropDownCheckedList"
import { useListeSoignantsDuCabinet } from "./hooks/useListeSoignantsDuCabinet"
import { ApercuPatient } from "@features/messagerie/ApercuPatient"

interface HeaderWithToolsProps {
  patient: PatientDuChannel | null | undefined
  profilPrincipal: boolean | null
  nonOnboarde: boolean | null
  onClickArchiver: () => void
  updateParticipants: (value: Soignant[]) => void
}

export const HeaderWithTools = ({
  patient,
  profilPrincipal,
  nonOnboarde,
  onClickArchiver,
  updateParticipants,
}: HeaderWithToolsProps) => {
  const { client } = useChatContext()
  const { channel } = useChannelStateContext()
  const { t } = useTranslation()
  const { messagerieMedecins } = useFlags()
  const { demanderAideMedecin, retirerDemandeAideMedecin } = useDemandeAideMedecin()
  const { listeSoignantsDuCabinet } = useListeSoignantsDuCabinet()

  const nomDuPatient = Object.values(channel.state?.members)
    .filter((member) => member.user?.role === "patient")
    .map((member) => (member.user?.prenom + " " + member.user?.nom) as string)
    .find((name) => name)

  const participantsPrimary: ParticipantProps[] =
    channel.state?.members &&
    Object.values(channel.state?.members)
      .map((member) => member.user)
      .filter((user) => user?.role === "soignant")
      .map((user) => user as unknown as ParticipantProps)

  const channelArchive = channel.data?.archive_le !== null && channel.data?.archive_le !== undefined
  const channelAvecDemandeAideMedecin = channel.data?.demandeAideMedecin === true
  const titre = channel.data?.objet as string

  const isUserMedecin = client.user?.metier === "medecin"

  return (
    <div className="mb-4 flex h-10 justify-between bg-white">
      <div className="flex flex-row items-center gap-2">
        <Avatar name={nomDuPatient || ""} color="bg-secondary-blue" />
        <div className="flex flex-col">
          <div className="flex flex-row items-center gap-2">
            <div className="text-h7">{nomDuPatient?.toUpperCase()}</div>
            <ApercuPatient patient={patient} profilPrincipal={profilPrincipal} nonOnboarde={nonOnboarde} />
            {patient?.lienDoctolib && patient?.lienDoctolib.trim() !== "" && (
              <a
                data-testid="lien-doctolib-patient"
                target="_blank"
                href={patient?.lienDoctolib}
                title={
                  t("messagerie.lienDoctolib") +
                  " " +
                  (patient?.prenom?.toUpperCase() ?? "") +
                  " " +
                  (patient?.nom?.toUpperCase() ?? "")
                }
                className="flex items-center"
              >
                <Doctolib className="h-[0.813em] w-[0.813em] text-h3" />
              </a>
            )}
          </div>
          <div className="text-h4">{titre}</div>
        </div>
      </div>
      <div className="flex items-center gap-2">
        {isUserMedecin && <CabinetTag cabinetId={channel.data?.team} />}

        <div className="flex -space-x-1">
          {participantsPrimary
            ?.reverse()
            .map((participant) => (
              <Participant key={participant.id} {...participant} className="text-md h-7 w-7 border border-white" />
            ))}
        </div>

        {messagerieMedecins && (
          <ToolButton
            onClick={async () => {
              if (channelAvecDemandeAideMedecin) {
                await retirerDemandeAideMedecin()
              } else {
                await demanderAideMedecin()
              }
            }}
            title={
              channelAvecDemandeAideMedecin
                ? t("messagerie.retirerDemandeAideMedecin")
                : t("messagerie.demanderAideMedecin")
            }
            icon={Flag}
            type={channelAvecDemandeAideMedecin ? "filled" : "outlined"}
            dataTestId="bouton-demande-aide-medecin"
          />
        )}
        <DropDownCheckedList
          titre={t("participants")}
          bouton={
            <ListboxButton className="group">
              <ToolButton title={t("messagerie.ajouterParticipant")} icon={Collaboration} type="outlined" />
            </ListboxButton>
          }
          liste={listeSoignantsDuCabinet ?? []}
          selection={participantsPrimary}
          setSelection={updateParticipants}
        />
        <ToolButton
          onClick={onClickArchiver}
          title={channelArchive ? t("messagerie.reactiver") : t("messagerie.archiver")}
          icon={Archive}
          type={channelArchive ? "tonal" : "outlined"}
          dataTestId="bouton-archiver"
        />
      </div>
    </div>
  )
}
