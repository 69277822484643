import { ComponentType } from "react"
import { rootRoute } from "./rootRoute.tsx"
import { format, isMatch } from "date-fns"
import { patientParIdentifiantQuery } from "@data/patient/patientParIdentifiantQuery.ts"
import { createRoute } from "@tanstack/react-router"
import { di } from "@di"

interface Pages {
  ChargementPage: ComponentType<object>
  ConsultationsPage: ComponentType<object>
  DetailPreconsultationPage: ComponentType<object>
  QuestionnairePanel: ComponentType<object>
  PatientPage: ComponentType<object>
  MessageriePage: ComponentType<object>
  ConversationPage: ComponentType<object>
  MessagerieIndexPage: ComponentType<object>
  ContextePatientPanel: ComponentType<object>
  register: (di: Partial<Pages>) => unknown
}

// @ts-expect-error("")
const registry: Pages = {}

export const register = (di: Partial<Pages>) => {
  Object.assign(registry, di)
}

const handler: ProxyHandler<Pages> = {
  get(target, property) {
    // @ts-expect-error("")
    if (target[property]) {
      // @ts-expect-error("")
      return target[property]
    }
    throw new Error(`Pages: ${String(property)} not registered`)
  },
}

const pages = new Proxy<Pages>(registry, handler)

export const appRoute = createRoute({
  getParentRoute: () => rootRoute,
  path: "/",
  component: () => <pages.ConsultationsPage />,
  validateSearch: (search: Record<string, unknown>): RootSearch => ({
    date:
      typeof search.date == "string" && isMatch(search.date, "yyyy-MM-dd")
        ? (search.date as string)
        : format(new Date(), "yyyy-MM-dd"),
    modal: typeof search.modal == "boolean" ? search.modal : undefined,
    tabIndex: typeof search.tabIndex == "number" ? search.tabIndex : undefined,
  }),
})

type RootSearch = {
  date: string
  modal?: boolean
  tabIndex?: number
}

export const chargementPageRoute = createRoute({
  getParentRoute: () => rootRoute,
  path: "/chargement",
  component: () => <pages.ChargementPage />,
})

export const preconsultationRoute = createRoute({
  getParentRoute: () => rootRoute,
  path: "/preconsultation/$id",
  component: () => <pages.DetailPreconsultationPage />,
})

export const contextePatientPanelRoute = createRoute({
  getParentRoute: () => preconsultationRoute,
  path: "/profil/$identifiantProfil",
  component: () => <pages.ContextePatientPanel />,
  loader: async ({ params: { identifiantProfil } }) => {
    return di.GraphQLQueryClient.queryFn(patientParIdentifiantQuery, {
      identifiant: identifiantProfil,
    })
  },
})

export const preconsultationQuestionnairePanelRoute = createRoute({
  getParentRoute: () => preconsultationRoute,
  path: "/questionnaire",
  component: () => <pages.QuestionnairePanel />,
})

export const patientRoute = createRoute({
  getParentRoute: () => rootRoute,
  path: "/patients/$id",
  component: () => <pages.PatientPage />,
})

export const messagerieRoute = createRoute({
  getParentRoute: () => rootRoute,
  path: "/messagerie",
  component: () => <pages.MessageriePage />,
})

export const conversationRoute = createRoute({
  getParentRoute: () => messagerieRoute,
  path: "/$conversationId",
  component: () => <pages.ConversationPage />,
})

export const messagerieIndexRoute = createRoute({
  getParentRoute: () => messagerieRoute,
  path: "/",
  component: () => <pages.MessagerieIndexPage />,
})
