import { useEffect, useState } from "react"
import { Modal, PlainButton, MagnifyingGlass } from "@ds"
import { useTranslation } from "react-i18next"
import { useGQLMutation, useGraphQLQuery } from "@data/useGraphQL.ts"
import { recherchePatientsQuery } from "@data/patient/recherchePatientQuery.ts"
import { PatientTrouve } from "@features/patient/pages/PatientTrouve.tsx"
import { SearchBar } from "@features/patient/pages/SearchBar.tsx"
import { creerUneConversationAvecLePatient } from "@data/messagerie/creerUneConversationAvecLePatient.ts"
import { usePreferences } from "@infra/preferences/usePreferences.tsx"
import { conversationRoute } from "@infra/navigation/router"
import { useNavigate } from "@tanstack/react-router"

export const useNouveauMessage = () => {
  const [modaleAffichee, setModaleAffichee] = useState(false)
  const { t } = useTranslation()

  const ModaleNouveauMessage = () => {
    const [recherche, setRecherche] = useState("")
    const [patientSelectionne, setPatientSelectionne] = useState<string | null>()
    const navigate = useNavigate()

    const { data } = useGraphQLQuery({
      document: recherchePatientsQuery,
      variables: { recherche },
      enabled: recherche.length > 2,
      keepDataWhileLoading: recherche.length > 2,
    })

    const { identifiantDuCabinet } = usePreferences()

    const { mutate: creerUneConversation } = useGQLMutation(creerUneConversationAvecLePatient, {
      onSuccess: async (data) => {
        if (data?.creerUneConversationAvecLePatient) {
          try {
            navigate({
              to: conversationRoute.to,
              params: {
                conversationId: data.creerUneConversationAvecLePatient.identifiantStreamChat,
              },
            })
          } catch (error) {
            // TODO: handle error
            console.error("Impossible de rejoindre la conversation", error)
          }
        }
      },
    })

    const onClose = () => {
      setRecherche("")
      setPatientSelectionne(null)
      setModaleAffichee(false)
    }

    useEffect(() => {
      setPatientSelectionne(null)
    }, [data?.data?.patients, recherche])

    return (
      <Modal
        open={modaleAffichee}
        title={t("messagerie.creerUneConversation")}
        onClose={onClose}
        onConfirm={() => {
          creerUneConversation({
            identifiantProfil: patientSelectionne!,
            identifiantDuCabinet: identifiantDuCabinet!,
          })
          onClose()
        }}
        dataTestId="modale-nouveau-message"
        cancelButton={false}
        confirmButtonTitle={t("messagerie.creerLaConversation")}
        disabled={!patientSelectionne}
      >
        <div className="flex w-full flex-col">
          <SearchBar
            icon={<MagnifyingGlass className="stroke-dark-plum text-xl text-light-grey group-hover:stroke-white" />}
            onType={setRecherche}
          />
          <>
            {data?.data?.patients && data?.data?.patients.length > 0 && (
              <>
                <div className="pt-4" />
                <div className="flex max-h-52 flex-col overflow-y-scroll border-t border-t-light-grey pt-4">
                  {data?.data?.patients?.map((patient) => (
                    <PatientTrouve
                      key={patient.id}
                      patient={patient}
                      selectPatient={setPatientSelectionne}
                      selected={patient.id === patientSelectionne}
                      disabled={patient.numeroDeTelephone === null}
                      disabledReason={t("messagerie.patientSansNumeroDeTelephone")}
                    />
                  ))}
                </div>
              </>
            )}
          </>
        </div>
      </Modal>
    )
  }

  const BoutonNouveauMessage = () => {
    return (
      <PlainButton
        title={t("messagerie.nouveauMessage")}
        type="outlined"
        data-testid="nouveau-message"
        onClick={() => setModaleAffichee(true)}
      />
    )
  }

  return {
    BoutonNouveauMessage,
    ModaleNouveauMessage,
  }
}
