import { ShimmerProvider } from "@ds"
import { AnalyticsIdentityBridge } from "@infra/analytics/AnalyticsIdentityBridge"
import { AuthProvider, Protected } from "@infra/auth"
import { StreamChatProvider } from "@infra/messagerie/StreamChatProvider"
import { QueryClientProvider } from "@tanstack/react-query"
import { ReactQueryDevtools } from "@tanstack/react-query-devtools"
import { Router, RouterHistory, RouterProvider } from "@tanstack/react-router"
import { asyncWithLDProvider, basicLogger } from "launchdarkly-react-client-sdk"
import React, { useState } from "react"
import { di } from "@di"

interface AppProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  router: Router<any, any, boolean, RouterHistory>
}

export const App = ({ router }: AppProps) => {
  const [LaunchDarklyProvider, setLaunchDarklyProvider] =
    useState<({ children }: { children: React.ReactNode }) => React.JSX.Element | null>()

  React.useEffect(() => {
    const setup = async () => {
      const ProviderComponent = await asyncWithLDProvider({
        clientSideID: import.meta.env.VITE_LAUNCH_DARKLY_CLIENT_ID as string,
        options: {
          logger: basicLogger({ level: "error" }),
        },
      })
      setLaunchDarklyProvider(() => ProviderComponent)
    }

    setup()
  }, [])

  if (!LaunchDarklyProvider) {
    return null // or a loading spinner
  }

  return (
    <React.StrictMode>
      <ShimmerProvider>
        <AuthProvider>
          <Protected
            component={() => (
              <LaunchDarklyProvider>
                <AnalyticsIdentityBridge />
                <QueryClientProvider client={di.GraphQLQueryClient.queryClient}>
                  <StreamChatProvider>
                    <RouterProvider router={router} />
                    <ReactQueryDevtools />
                  </StreamChatProvider>
                </QueryClientProvider>
              </LaunchDarklyProvider>
            )}
          />
        </AuthProvider>
      </ShimmerProvider>
    </React.StrictMode>
  )
}
