import { SVGProps } from "react"

export const WarningSolid = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 20 21" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M20 10.914c0 5.523-4.477 10-10 10s-10-4.477-10-10 4.477-10 10-10 10 4.477 10 10Zm-11 5a1 1 0 1 1 2 0 1 1 0 0 1-2 0Zm.25-3a.75.75 0 0 0 1.5 0v-7a.75.75 0 0 0-1.5 0v7Z"
      clipRule="evenodd"
    />
  </svg>
)
